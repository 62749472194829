<template>
  <div>
    <section class="w-full">
      <header-register
      :showButtonView="false"
      >{{ $t("sectionAdmCartoes.cardAdministrator") }}
    </header-register>
    <main class="mt-3">
      <EditCard />
    </main>
    </section>
  </div>
</template>

<script>
  import EditCard from '@/components/administratorCard/components/edit/EditCard.vue';
  import HeaderRegister from "@core/components/register/HeaderRegister.vue";

export default {
  name: "AdmCardEdit",
    components: {
      EditCard,
      HeaderRegister
    },
    data() {
      return {
      };
    },
  }
</script>